<template>
  <div class="join-our-fleet">
    <div class="join-our-fleet__main">
      <PageHeading
        :title="'Join Our Fleet'"
        :text="'We are looking for drivers who want to provide our riders with top-quality care and earn on their schedule. We strive to provide white glove service to our riders by going the extra mile during each experience. Drivers will earn a flat rate for rides, so you always know how much you will make. '"
      />
     
    </div>
    <TimelineBlock
        :items="howItWorks"
        :title="'How it works'"
        :type="'white'"
      />
    <div class="join-our-fleet__contact-us">
      <ContactPopup />
    </div>
  </div>
</template>

<script>
import ContactPopup from "../components/ContactUs.vue";
import PageHeading from "../components/PageHeading.vue";
import TimelineBlock from "../components/TimelineBlock.vue";

export default {
  name: "JoinOurFleet",
  components: {
    ContactPopup,
    PageHeading,
    TimelineBlock,
  },
  data: () => ({
    howItWorks: [
      {
        img: "images/home/home_how-it-1.png",
        title: "Inquire",
        text: "Sign up to drive using the form below. Once your registration process is complete, you can start earning.",
      },
      {
        img: "images/home/home_how-it-1.png",
        title: "Training",
        text: "We will provide all of the training online so you can provide the best service to our riders.",
      },
      {
        img: "images/home/home_how-it-2.png",
        title: "Drive",
        text: "Start driving and earing with anytime support. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;<span style='color:#3e688a'>.</span>",
      },
    ],
  }),
};
</script>

<style lang="scss">
.join-our-fleet {

  display: flex;
  flex-flow: column;
  align-items: center;

  background: $primary-color;
  &__main {
  }

  &__contact-us {
    width: 100%;
    padding: 120px 25px;

    background: #ffffff;

    position: relative;
    z-index: 2;

    @media screen and (max-width: 940px) {
      padding: 60px 25px;
    }
  }
}
</style>