<template>
  <div
    class="page-heading"
    :class="{
      'primary-type': type == 'primary',
      'white-type': type == 'white',
    }"
  >
    <div class="page-heading__body">
      <div class="page-heading__title">{{ title }}</div>
      <div class="page-heading__text">{{ text }}</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      value: String,
      default: null,
    },
    text: {
      value: String,
      default: null,
    },
    type: {
      value: String,
      default: "white",
    },
  },
};
</script>

<style lang="scss">
.page-heading {
  width: 100%;
  padding: 100px 0 0 0;

  @media screen and (max-width: 940px) {
    padding: 60px 0 0 0;
  }

  &.primary-type {
    background: $white;
  }

  &.primary-type &__title {
    color: $primary-color;
  }

  &.primary-type &__text {
    color: $primary-color;
  }

  &.white-type {
    background: $primary-color;
  }

  &.white-type &__title {
    color: $white;
  }

  &.white-type &__text {
    color: $white;
  }

  &__body {
    width: 100%;
    max-width: $containerMaxWidth;
    margin: 0px auto;

    padding: 0 25px;
  }

  &__title {
    @include adaptiv-font(48, 30);
    font-weight: 700;
    text-align: center;

    margin-bottom: 40px;
  }

  &__text {
    @include adaptiv-font(24, 18);
    font-weight: 500;
    text-align: center;
    line-height: 140%;
  }
}
</style>